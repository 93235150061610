import React, { ReactElement } from "react";
import { Skeleton as MuiSkeleton, SkeletonProps as MuiSkeletonProps } from "@mui/material";

type SkeletonProps = {
  children?: ReactElement | ReactElement[] | string;
  show: boolean;
} & Pick<MuiSkeletonProps, "width" | "height" | "variant">;

const Skeleton = ({
  children,
  show,
  width,
  height,
  ...other
}: SkeletonProps): React.ReactElement | React.ReactElement[] | string | undefined => {
  if (show) {
    return (
      <MuiSkeleton
        data-testid="skeleton"
        {...other}
        width={width}
        height={height}
      >
        {children}
      </MuiSkeleton>
    );
  }
  return children;
};

export default Skeleton;
