import React, { ReactElement, Suspense } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { LoadingSpinner } from "@components/routes/utils";
import withQueryParams from "@context/withQueryParams";
import LevisCollectLateralLayout from "@components/collectLateral/LevisCollectLateralLayout";
import { useFeatureFlagsContext } from "@context/featureFlags/FeatureFlagsContext";
import NoMatch from "@components/NoMatch";


const CollectLateralPage = (): ReactElement => {
  const { featureFlags } = useFeatureFlagsContext();

  if (!featureFlags["access-levis-lateral-web-page"]) {
    return <NoMatch />;
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route
          path="/"
          element={<Outlet />}
        >
          <Route
            path=""
            element={<LevisCollectLateralLayout />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default withQueryParams(CollectLateralPage);
