/**
 * French (Canada) translation keys for i18next.
 * @module fr-ca
 */
export default {
  actions: "Actions",
  active: "Actif",
  add_contact: "Ajouter un contact",
  add_customer: "Ajouter un client",
  add_item: "Ajouter un item",
  add_job: "Ajouter une tâche",
  add_location: "Ajouter un emplacement",
  add_route_template: "Ajouter un modèle de route",
  add_route: "Planifier une route",
  add_task: "Ajouter une tâche",
  add: "Ajouter",
  "address.adr_1": "Adresse 1",
  "address.adr_2": "Adresse 2 (facultatif)",
  "address.adr_normal": "Adresse",
  "address.adr": "Adresse",
  "address.city": "Ville",
  "address.country": "Pays",
  "address.door_no": "Numéro civique",
  "address.name": "Nom (facultatif)",
  "address.note": "Note",
  "address.postal_code": "Code postal",
  "address.province": "Province",
  alias: "Alias",
  all_the: "Toutes les",
  apply: "Appliquer",
  available_locations: "Emplacement(s) non-assigné(s)",
  available: "Disponible",
  blocked_customer: "Le client est bloqué",
  blocking_sheet: "Fiche de blocage",
  "buttons.actions.add": "Ajouter",
  "buttons.actions.create": "Créér",
  "buttons.actions.next": "Suivant",
  "buttons.actions.close": "Fermer",
  "buttons.actions.exit": "Quitter",
  "buttons.actions.previous": "Retour",
  "buttons.actions.cancel": "Annuler",
  calender: "Calendrier",
  cancel: "Annuler",
  change_view: "Changer la vue",
  characteristic: "Caractéristique",
  ci_web_chipcode: "Numéro de puce",
  ci_web_labelcode: "Numéro de contenant",
  client_contract: "Client / Contrat",
  client: "Client",
  close: "Fermer",
  code: "Code",
  collect_day: "Jour de collecte",
  collect_hour: "Heure de collecte (facultatif)",
  comment: "Commentaire",
  comments: "Commentaires",
  "common.deselect": "Désélectionner",
  "common.select": "Sélectionner",
  "common.title.assignation": "Répartition",
  "common.title.client": "Clients",
  "common.title.inventory": "Inventaire",
  "common.title.jobs": "Tâches",
  "common.title.preparation": "À Préparer",
  "common.title.quickbooks": "Comptabilité",
  "common.title.sales": "Ventes",
  "common.title.units": "Unités",
  "common.title.roll_off": "Roll-off",
  connect: "Connectez-vous",
  "contact.add_contact": "Ajouter un contact",
  "contact.cell_phone_full": "Téléphone cellulaire",
  "contact.cell_phone": "Tél. cellulaire",
  "contact.contact_information": "Informations sur le client",
  "contact.email": "Courriel",
  "contact.ext": "Ext.",
  "contact.first_name": "Prénom",
  "contact.home_phone_full": "Téléphone domicile",
  "contact.home_phone": "Tél. domicile",
  "contact.last_name": "Nom de famille",
  "contact.name": "Nom",
  "contact.work_phone_full": "Téléphone bureau",
  "contact.work_phone": "Tél. Bureau",
  contacts: "Contacts",
  container_chipcode: "Numéro de puce électronique",
  container_kind: "Type de matière",
  container_model: "Modèle de conteneur",
  container_number_new: "Nouveau numéro de containeur si changement",
  container_number: "Numéro de conteneur",
  container_size: "Taille du conteneur",
  container_type: "Type de conteneur",
  container_used: "Conteneur utilisé (facultatif)",
  continue: "Continuer",
  contract_and_contact: "Contrats et contacts",
  contract_start: "Début du contrat",
  contract: "Contrat",
  contracts: "Contrats",
  copy: "Copier",
  created_by: "Créé par",
  creation_date: "Date de création",
  "credit_card.expiration_date": "Date d'expiration",
  "credit_card.holder_name": "Nom du détenteur",
  "credit_card.information": "Informations de la carte de crédit",
  "credit_card.number": "Numéro",
  "credit_card.security_code": "Code de sécurité",
  "credit_card.title": "Carte de crédit",
  customer_account: "Compte client",
  customer_destination_location: "Destination",
  customer_item_last_work_location: "Dernier item à cette adresse",
  customer_item_name: "Item",
  customer_item: "Item de contrat",
  customer_items: "Items de contrat",
  customers: "Clients",
  "date.day_names.0": "Dimanche",
  "date.day_names.1": "Lundi",
  "date.day_names.2": "Mardi",
  "date.day_names.3": "Mercredi",
  "date.day_names.4": "Jeudi",
  "date.day_names.5": "Vendredi",
  "date.day_names.6": "Samedi",
  "date.month_names.0": "",
  "date.month_names.1": "janvier",
  "date.month_names.10": "octobre",
  "date.month_names.11": "novembre",
  "date.month_names.12": "décembre",
  "date.month_names.2": "février",
  "date.month_names.3": "mars",
  "date.month_names.4": "avril",
  "date.month_names.5": "mai",
  "date.month_names.6": "juin",
  "date.month_names.7": "juillet",
  "date.month_names.8": "août",
  "date.month_names.9": "septembre",
  "date.order.0": ":year",
  "date.order.1": ":month",
  "date.order.2": ":day",
  day: "Jour",
  days_of_month: "jour(s) du mois",
  days_of_week: "jour(s) de la semaine",
  days: "jour(s)",
  delete_job: "Supprimer la tâche",
  delete: "Supprimer",
  "delivery_notes.add_delivery_note": "Ajouter une note de livraison",
  "delivery_notes.add": "Ajouter",
  "delivery_notes.back_to_active_notes": "Revenir aux notes actives",
  "delivery_notes.check_to_archive": "Cocher pour archiver",
  "delivery_notes.date": "Date",
  "delivery_notes.edit_delivery_note": "Modifier une note de livraison",
  "delivery_notes.name": "Nom",
  "delivery_notes.note": "Note",
  "delivery_notes.search_completed_notes": "Consulter les notes archivées",
  "delivery_notes.title": "Notes de livraison",
  deposit: "Dépôt (facultatif)",
  deselect: "Déselectionner",
  details: "Détail (facultatif)",
  dispatch: "Répartir",
  download_data: "Chargement des données...",
  download_file: "Chargement du fichier...",
  download: "Chargement",
  drop_point_1: "Point de chute",
  drop_point_2: "Second point de chute (facultatif)",
  duration: "Durée (min)",
  edit_address: "Modifier une adresse",
  edit_contact: "Modifier un contact",
  edit_customer: "Modifier un client",
  edit_geolocation: "Modifier une géoposition",
  edit_intervention: "Modifier une intervention",
  edit_job: "Modifier une tâche",
  edit_location: "Modifier un emplacement",
  edit_route_template: "Modifier",
  edit_route: "Modifier la route",
  edit_task: "Modifier une tâche",
  edit: "Modifier",
  end_date: "Fin",
  error: "Erreur",
  "errors.blank": "Ne peut être vide",
  "errors.invalid": "Format invalide",
  every_x_weeks: "Aux x semaines",
  expand: "Ouvrir",
  filter: "Filtre",
  flow: "Flow",
  "frequencies.annualy": "Annuelle",
  "frequencies.daily": "Quotidienne",
  "frequencies.monthly": "Aux 4 semaines",
  "frequencies.twice_monthly": "Aux 2 semaines",
  "frequencies.weekly": "Hebdomadaire",
  frequency_of_lifting_to_import: "Fréquence des levées à importer",
  frequency: "Fréquence",
  from: "De",
  historical_record: "Historique",
  hour: "Heure",
  interruption: "Interruption",
  "interruptions.DI": "Déplacement Inutile",
  intervention_duration: "Durée d'intervention (min) (facultatif)",
  interventions: "Interventions",
  "items.add_item": "Ajouter un item",
  "items.company_inventory": "Inventaire",
  "items.edit_item": "Modifier un item",
  "items.identifier": "Numéro",
  "items.item_kind": "Type",
  "items.item_spec": "Caract.",
  "items.status": "Statut",
  "items.statuses.active": "Actif",
  "items.statuses.auto_blocked": "Bloqué auto.",
  "items.statuses.blocked": "Bloqué",
  "items.statuses.inactive": "Inactif",
  "items.title": "Item",
  "jobs_history.date": "Date",
  "jobs_history.name": "Nom",
  "jobs_history.status": "Statut",
  "jobs_history.type": "Type",
  jobs_scheduled_on: "{{kind}} du {{date}}",
  "jobs.creation_date": "Prévue le",
  "jobs.duration_long": "Durée : {{duration}} min.",
  "jobs.duration": "Durée (min)",
  "jobs.end_date": "Terminée le",
  "jobs.job_creation": "Création de la tâche...",
  "jobs.kind": "Flow",
  "jobs.start_date": "Prévue le",
  "jobs.status": "Statut",
  "jobs.statuses.completed": "Terminée",
  "jobs.statuses.in_progress": "En cours",
  "jobs.statuses.interrupted": "Interrompue",
  "jobs.statuses.todo": "À faire",
  "jobs.unassigned": "Non assignée",
  "jobs.unit": "Assignée à",
  kind: "Type",
  "loading_types.front_loading": "Chargement avant",
  "loading_types.rear_loading": "Chargement arrière",
  "loading_types.roll_off": "Roll-off",
  "loading_types.side_loading": "Chargement latéral",
  location_and_contact: "Emplacements et contacts",
  location_detail_type_to_add: "Type de détail à ajouter",
  location_detail_type: "Type de détail",
  location_or_route_name: "Emplacement ou nom de la route",
  "location.district_number": "Arrondissement",
  "location.file_number": "No. de dossier",
  "location.name": "Nom de l'emplacement",
  "location.title": "Emplacement",
  "location.valid_file_number": "Ne peut contenir que des chiffres",
  locations: "Emplacements",
  logout: "Déconnexion",
  "liftings.categories.additional_registration": "Additionnelle",
  "liftings.categories.regular_registration": "Régulière",
  "liftings.categories.new_contract": "Nouveau contrat",
  "liftings.categories.end_contract": "Fin de contrat",
  "liftings.categories.additional_registration_outside_zone": "Hors-zone",
  "liftings.categories.additional_registration_particular_day": "Demande particulières de jour",
  "liftings.categories.additional_registration_particular_night_or_week_end": "Demande particulières de soir et fin de semaine",
  "liftings.lifting_types.no_lifting": "Ne pas levée",
  "liftings.lifting_types.regular": "Levée régulière",
  "liftings.lifting_types.postponed_regular": "Levée régulière reportée",
  "liftings.lifting_types.extra": "Levée supplémentaire",
  "liftings.lifting_types.postponed_extra": "Levée supplémentaire reportée",
  "liftings.lifting_types.service": "Levée reprise",
  "liftings.lifting_types.postponed_service": "Levée reprise reportée",
  matter: "Matière",
  "matter.recycling": "Recyclage",
  "matter.trash": "Déchets",
  "matter.organic": "Matières organique",
  memo: "Memo",
  model: "Modèle",
  months_for_the: "mois pour les",
  name_simple: "Nom",
  nb_container: "Nombre de conteneur",
  nb_lifting: "Nombre de levée",
  no_data: "Aucune donnée",
  no_result: "Aucun résultat",
  no: "n°",
  not_applicable: "N/A",
  not_selected: "Non sélectionné",
  note_item_contract: "Note sur l'item de contrat",
  optional: "(facultatif)",
  paid: "Prépayé",
  parts: "Pièces",
  place: "Emplacement",
  planified: "Planifié",
  "preparation.add_customer_button": "Ajouter",
  "preparation.add_location_button": "Ajouter",
  "preparation.note_access": "Procédure d'accès particulière",
  "preparation.note_before_start": "Consignes avant le départ (facultatif)",
  "preparation.note_comments": "Note de la tâche",
  "preparation.note_location": "Note de l'emplacement",
  "preparation.note_route": "Note de la route",
  "preparation.note_schedule": "Heure de collecte (facultatif)",
  preparations: "Préparations",
  prepare: "Préparer",
  prepared: "Préparé",
  "provinces.ab": "Alberta",
  "provinces.bc": "Colombie-Britannique",
  "provinces.mb": "Manitoba",
  "provinces.nb": "Nouveau-Brunswick",
  "provinces.nl": "Terre-Neuve-et-Labrador",
  "provinces.ns": "Nouvelle-Écosse",
  "provinces.nt": "Territoires du Nord-Ouest",
  "provinces.nu": "Nunavut",
  "provinces.on": "Ontario",
  "provinces.pe": "Île-du-Prince-Édouard",
  "provinces.qc": "Québec",
  "provinces.sk": "Saskatchewan",
  "provinces.fl": "États-Unis - Floride",
  "provinces.yt": "Yukon",
  purchase_order_no_short: "No. bon de commande",
  purchase_order_no: "Numéro de bon de commande (facultatif)",
  quickbooks_not_connected_text: "Veuillez connecter votre compte Quickbooks.",
  quickbooks_not_connected_title: "Quickbooks n'est pas connecté à Villéco",
  reason_for_blocking: "Raison du blocage...",
  recurrence_creation: "Création des récurrences en cours...",
  recurrence: "Récurrences",
  refresh: "Actualiser",
  repeat_tasks: "Répétition de la tâche :",
  report_type: "Sélectionner un type de rapport...",
  report: "Rapport",
  results: "Résultats",
  "route_location.date": "Date prévue",
  "route_location.done_date": "Levée effecutée le",
  "route_location.events.anomaly": "Cas problème",
  "route_location.events.automaticLifting": "Levée auto.",
  "route_location.events.manualLifting": "Levée manuelle",
  "route_location.name": "Nom de la route",
  "route_location.recurrence": "Récurrence",
  "route_location.statuses.finishedWithAnomaly": "Comp/Ano",
  "route_location.statuses.finishedWithOnlyAnomaly": "Cas problèmes",
  "route_location.statuses.finishedWithoutAnomaly": "Complétés",
  "route_location.statuses.hasNoLiftingRequest": "Sans levée",
  "route_location.statuses.unfinished": "À faire",
  "route_templates.add_location": "Ajouter un nouvel emplacement",
  "route_templates.add_route_master": "Ajouter une route maître",
  "route_templates.average_lifting_time": "Temps de levée moyen (sec)",
  "route_templates.conception": "Modèles de Routes",
  "route_templates.delete": "Supprimer",
  "route_templates.edit_route_template_sub_route": "Modifier une sous-route",
  "route_templates.lifting_count": "Nombre de levées",
  "route_templates.master_route_name": "Route-maître",
  "route_templates.name": "Nom",
  "route_templates.route_locations_count": "Nombre d'emplacements",
  "route_templates.select_route_template_error": "Veuillez faire une sélection",
  "route_templates.sub_route": "Ajouter une sous-route",
  "route_templates.subroutes": "Sous-routes",
  "route_templates.title": "Modèles de route",
  "route_templates.unassign": "Désassigner",
  "route_templates.unassigned": "Non-assignés",
  "route_templates.view_route_master": "Visualiser une route-maître",
  route: "Route",
  "routes.add_lifting": "Ajouter une levée",
  "routes.anomaly": "Anomalie(s)",
  "routes.assignation": "Planification routes",
  "routes.assigned_to": "Assignée à",
  "routes.assigned": "Assignée",
  "routes.completed": "Completé(s)",
  "routes.lifting_done_count": "Levées effectuées",
  "routes.lifting_to_do_count": "Levées prévues",
  "routes.master_route_name": "Route maître",
  "routes.name": "Nom",
  "routes.nb_declared_anomalies": "Cas problèmes déclarés",
  "routes.nb_route_locations_anomalied": "Conteneurs avec cas problèmes",
  "routes.note_comments": "Commentaires",
  "routes.remaining_lifting_count": "Levées restantes",
  "routes.remaining": "Restant(s)",
  "routes.route_already_assigned_to_date": "Route déjà assignée à cette date",
  "routes.route_creation": "Création de la route...",
  "routes.route_template": "Sous route",
  "routes.title": "Routes",
  "routes.total": "Total",
  "routes.totalRouteLocations": "Total emplacements",
  "routes.totalRouteLocationsAnomaly": "Total anomalies ",
  "routes.totalRouteLocationsDone": "Total des emplacements effectués",
  "routes.totalRouteLocationsNotDone": "Total des emplacements restants",
  "routes.type.regular": "Régulière",
  "routes.type.extra": "Supplémentaire",
  rows_per_page: "Lignes par page",
  save: "Enregistrer",
  schedule: "Calendrier",
  search: "Rechercher",
  select_all: "Tout sélectionner",
  select_container_fraction: "Sélectionner un type de matière",
  select_container_type: "Sélectionner un type de conteneur",
  select_container: "Sélectionner un conteneur",
  select_contract: "Sélectionner un contrat",
  select_customer_destination_location: "Sélectionner une destination",
  select_customer_item: "Sélectionner un item de contrat",
  select_customer: "Sélectionner un client",
  select_deposit: "Sélectionner un dépôt",
  select_district_number: "Sélectionner un arrondissement",
  select_drop_point: "Sélectionner un point de chute",
  select_flow: "Sélectionner un flow",
  select_loading_type: "Sélectionner un type de chargement",
  select_location_detail_type: "Sélectionner un type de modalité",
  select_location: "Sélectionner un emplacement",
  select_province: "Sélectionner une province",
  select_route_template_id: "Sélectionner une route",
  select_unit: "Sélectionner une unité",
  selected_locations: "Emplacement(s) assignés(s)",
  show_pictures: "Photos",
  simplied_view: "Vue simplifiée",
  start_date: "Début",
  "status.all": "Tous",
  "status.not_planned": "Sans tâche",
  "status.not_prepared": "Sans préparation",
  "status.title": "Statut",
  "status.wo_items": "Sans conteneur",
  switch_to_data_table_mode: "Passer en mode table de données",
  switch_to_drag_and_drop_mode: "Passer en mode glisser-déposer",
  task_copy: "Faire une copie de la tâche",
  task: "Tâche",
  tasks_planification: "Tâches à planifier",
  tasks: "Tâches",
  teams: "Équipes",
  time: "Horaire",
  times: "Fois",
  tooltip_pending_assignations: "Répartition(s) non-confirmée(s)",
  type: "Type",
  "unit_select.all_selected": "Toutes les unités",
  "unit_select.many_selected": "Unités sélectionnées",
  "unit_select.none_available": "Aucune unité disponible",
  "unit_select.none_selected": "Aucune unité sélectionnée",
  "unit_select.one_selected": "Unité sélectionnée",
  unit: "Unité (facultatif)",
  "units.add_unit": "Ajouter une unité",
  "units.axles": "Nombre d'essieux",
  "units.bucket_brand": "Marque de la benne",
  "units.bucket_loading_capacity_imperial": "Capacité de chargement de la benne (ve³)",
  "units.bucket_loading_capacity_metric": "Capacité de chargement de la benne (m³)",
  "units.bucket_model": "Modèle de la benne",
  "units.compaction_and_unloading_systems": "Description des systèmes de compaction et de déchargement de la benne",
  "units.documents": "Documents",
  "units.edit_unit": "Modifier une unité",
  "units.ghg_reduction_measures": "Description des mesures de réduction des GES",
  "units.help_on_unit": "Aide sur l'unité",
  "units.identifier": "Numéro",
  "units.loading_type": "Type de chargement",
  "units.name": "Nom",
  "units.note": "Notes",
  "units.registration_certificate": "Certificat d'immatriculation du camion",
  "units.title": "Unité",
  "units.truck_brand": "Marque du camion",
  "units.truck_id": "Identification",
  "units.truck_model": "Modèle du camion",
  "units.truck_registration": "Numéro d'immatriculation du camion",
  "units.truck_serial_number": "Numéro de série du camion",
  "units.unit_type": "Choisir le type d'unité",
  "units.unloaded_weight": "Poids à vide (kg)",
  "units.upload": "Téléverser",
  "units.user_id": "Utilisateur",
  "units.year": "Année",
  unselect_unit: "Désassigner la route",
  until: "Jusqu'à",
  upload: "Téléverser",
  use: "Utiliser",
  used: "Utilisé",
  validate_postal_code: "Doit être du format suivant : G7J 4W7 ou G7J4W7",
  validate_route_master_name: "Exemple : CCAV-A1-R-LU ou CCAV-PORTNEUF-R-LU (Type-Arrondissement-Matière-Jour)",
  validate_route_name: "Exemple : 1",
  volume_container: "Volume du conteneur",
  volume: "Volume",
  warning_archive_customer: "Êtes-vous certain de vouloir archiver ce client?",
  warning_assigned_route: "Route assignée, suppression impossible",
  warning_customer_item_delete: "Déjà planifié, suppression impossible",
  warning_delete_certificate: "Êtes-vous certain de vouloir supprimer ce document?",
  warning_delete_contact: "Êtes-vous certains de vouloir supprimer ce contact ?",
  warning_delete_contract_item: "Êtes-vous certains de vouloir supprimer cet item de contrat ?",
  warning_delete_customer: "Êtes-vous certains de vouloir supprimer ce client ?",
  warning_delete_delivery_note: "Êtes-vous certain de vouloir supprimer cette note de livraison?",
  warning_delete_item: "Êtes-vous certains de vouloir supprimer cet item ?",
  warning_delete_job: "Êtes-vous certains de vouloir supprimer cette tâche ?",
  warning_delete_location: "Êtes-vous certains de vouloir supprimer cet emplacement ?",
  warning_delete_recurrence: "Êtes-vous certains de vouloir arrêter cette récurrence ?",
  warning_delete_route_template:
    "Êtes-vous certains de vouloir supprimer ce modèle de route ? Cette action supprimera également les routes déjà planifiées et assignées ",
  warning_delete_route: "Êtes-vous certains de vouloir supprimer cette route ?",
  warning_delete_unit: "Êtes-vous certain de vouloir supprimer cette unité ?",
  warning_delete: "Suppréssion en cours...",
  warning_job_not_editable: "La tâche est terminée, elle ne peut pas être éditée",
  warning_must_be_prepared: "Doit d'abord être préparé",
  warning: "Attention",
  "weekdays_location.complete.friday": "Vendredi",
  "weekdays_location.complete.monday": "Lundi",
  "weekdays_location.complete.saturday": "Samedi",
  "weekdays_location.complete.sunday": "Dimanche",
  "weekdays_location.complete.thursday": "Jeudi",
  "weekdays_location.complete.tuesday": "Mardi",
  "weekdays_location.complete.wednesday": "Mercredi",
  "weekdays_location.friday": "Ve",
  "weekdays_location.monday": "Lu",
  "weekdays_location.saturday": "Sa",
  "weekdays_location.sunday": "Di",
  "weekdays_location.thursday": "Je",
  "weekdays_location.tuesday": "Ma",
  "weekdays_location.wednesday": "Me",
  "weekdays.fri": "V",
  "weekdays.mon": "L",
  "weekdays.sat": "S",
  "weekdays.sun": "D",
  "weekdays.thur": "J",
  "weekdays.tues": "M",
  "weekdays.wed": "M",
  weeks: "semaine(s)",
  weight_unit: "Poids de l'unité",
  weight: "Poids",
  work: "Travail",
  works_start: "Début des travaux :",
  xlsx_file_download: "Téléchargement du fichier xlsx...",
  years: "année(s)",
};
