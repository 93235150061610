import React, { createContext, ReactElement, useMemo } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { MapErrorView } from "@utils/withGoogleMap";
import useMapProps, { CustomMapProps } from "@hooks/useMapProps";
import Skeleton from "@ui/skeleton";
import { APIProvider } from "@vis.gl/react-google-maps";

type ContextState = {
  mapProps: CustomMapProps;
  setCenter: (position: google.maps.LatLngLiteral, zoom?: number) => void;
  setZoom: (zoom: number) => void;
  resetCenter: () => void;
};

export const GoogleMapContext = createContext({} as ContextState);

type Props = {
  children: ReactElement;
};

function GoogleMapProvider({ children }: Props): ReactElement {
  const { mapProps, setZoom, setCenter, resetCenter } = useMapProps();

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_KEY || "",
  });

  const value: ContextState = useMemo(
    () => ({
      mapProps,
      resetCenter,
      setCenter,
      setZoom,
    }),
    [mapProps, setCenter, setZoom, resetCenter],
  );

  if (!isLoaded)
    return (
      <Skeleton
        show
        height="100vh"
        variant="rectangular"
      />
    );

  if (loadError) return <MapErrorView />;

  return (
    <GoogleMapContext.Provider value={value}>
      <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY || ""}>{children}</APIProvider>
    </GoogleMapContext.Provider>
  );
}

function useGoogleMapContext(): ContextState {
  const context = React.useContext(GoogleMapContext);
  if (context === undefined) {
    throw new Error("useGoogleMapContext must be used within a GoogleMapProvider");
  }
  return context as unknown as ContextState;
}

/**
 * Children component
 * @param Component
 */
function withGoogleMapContext<T extends React.JSX.IntrinsicAttributes = React.JSX.IntrinsicAttributes>(
  Component: React.ComponentType<T>,
): React.ComponentType<T> {
  return function fn(props: T) {
    return (
      <GoogleMapProvider>
        <Component {...props} />
      </GoogleMapProvider>
    );
  };
}

export { GoogleMapProvider, withGoogleMapContext, useGoogleMapContext };
