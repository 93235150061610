import { GeoJsonLayer, Layer, LayersList, TextLayer, Position as PositionGL } from "deck.gl";
import { Feature, FeatureCollection, Polygon, Position } from "geojson";

export type CreateTextLayerParams<T extends Feature> = {
  id: string;
  text: string;
  position: Position;
  data: T[];
}

class LayerService {
  layers: { [key: string]: Layer };

  constructor() {
    this.layers = {};
  }

  public getLayers(): LayersList {
    return Object.values(this.layers);
  }

  public getLayerIds(): string[] {
    return Object.keys(this.layers);
  }

  public getLayer(id: string): Layer | undefined {
    return this.layers[id];
  }

  public setLayer(id: string, layer: Layer): void {
    this.layers[id] = layer;
  }

  addLayer(id: string, layer: Layer): void {
    this.layers[id] = layer;
  }

  removeLayer(layer: Layer): void {
    const id = layer.id;

    if (this.getLayer(id)) {
      delete this.layers[id];
    }
  }

  public createGeoJsonLayer<T extends Feature>(id: string, data: FeatureCollection<T["geometry"]>): GeoJsonLayer<FeatureCollection<T["geometry"]>> {
    const layer = new GeoJsonLayer({
      id: id,
      data: data,
      lineWidthMinPixels: 0.5,
      getLineColor: [255, 255, 255, 255],
      getFillColor: [0, 133, 202, 100],
      getLineWidth: 32,
      lineWidthScale: 1,
      getPointRadius: 10,
      pickable: true,
    });

    this.addLayer(id, layer);

    return layer;
  }

  public createTextLayer<T extends Feature<Polygon>>({id, text, position, data}: CreateTextLayerParams<T>): TextLayer {
    const layer = new TextLayer({
      id: id,
      data: data,
      getPosition: position as PositionGL,
      getText: () => text,
      getSize: 20,
      getAngle: 0,
      getTextAnchor: 'middle',
      getAlignmentBaseline: 'center',
      getPixelOffset: [0, 0],
      getColor: [0, 0, 0, 200],
      fontFamily: 'Arial',
      fontWeight: 'bold',
    })

    this.addLayer(id, layer);

    return layer;
  }
}

export default LayerService;

