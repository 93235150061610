import { useCallback, useState } from "react";
import { MapProps } from "@vis.gl/react-google-maps";

export type CustomMapProps = Pick<MapProps, "zoom" | "defaultCenter" | "center">;

const initialMapProps: CustomMapProps = {
  defaultCenter: {
    lat: 46.807763,
    lng: -71.38549,
  },
  center: null,
  zoom: 12,
};

export type UseMapPropsResult = {
  mapProps: CustomMapProps;
  setCenter: (position: google.maps.LatLngLiteral, zoom?: number) => void;
  setZoom: (zoom: number) => void;
  resetCenter: () => void;
};

const useMapProps = (): UseMapPropsResult => {
  const [mapProps, setMapProps] = useState<CustomMapProps>(initialMapProps);

  const resetCenter = useCallback((): void => {
    setMapProps((prevState) => ({
      ...prevState,
      center: null,
    }));
  }, []);

  const setCenter = useCallback(({ lat, lng }: google.maps.LatLngLiteral, newZoom?: number): void => {
    setMapProps((prev) => {
      return {
        ...prev,
        center: {
          lat,
          lng,
        },
        defaultCenter: undefined,
        zoom: newZoom || prev.zoom,
      };
    });
  }, []);

  const setZoom = useCallback((zoom: number): void => {
    setMapProps((prevState) => ({
      ...prevState,
      center: null,
      zoom,
    }));
  }, []);

  return {
    mapProps,
    setCenter,
    setZoom,
    resetCenter,
  };
};

export default useMapProps;
