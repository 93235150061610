import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Container, MapContainer, MapSection } from "@components/collectLateral/LevisCollectLateral.styles";
import { withGoogleMapContext } from "@context/googleMap/GoogleMapContext";
import { load } from "@loaders.gl/core";
import { KMLLoader } from "@loaders.gl/kml";
import { LayersList } from "@deck.gl/core";
import { Feature, FeatureCollection, Polygon } from "geojson";
import DeckGLMapContainer from "@components/maps/DeckGLMapContainer";
import LayerService from "../../services/maps/layer-service";
import GeoSpatialService from "../../services/maps/geospatial-service";

export type Sector = {
  id: string;
  name: string;
} & Feature<Polygon>;

const LevisCollectLateralLayout = (): ReactElement => {
  const layerService = useMemo(() => new LayerService(), []);
  const geoSpatialService = useMemo(() => new GeoSpatialService(), []);

  const [, setSectors] = useState<Sector[]>([]);
  const [layers, setLayers] = useState<LayersList>(layerService.getLayers());

  useEffect(() => {
    load("/lateral_levis.kml", KMLLoader).then((data) => {
      const featureCollection = data as FeatureCollection<Polygon>;

      const sectorsData: Sector[] = featureCollection.features.map((feature) => {
        const s: Sector = {
          type: 'Feature',
          id: feature.properties?.SECTEURID,
          name: feature.properties?.SECTEURID,
          geometry: feature.geometry,
          properties: feature.properties
        }

        console.log(s);
        return s;
      })

      layerService.createGeoJsonLayer("geojson", featureCollection);

      sectorsData.forEach((sector) => {
        layerService.createTextLayer({
          id: sector.id,
          data: [sector],
          position: geoSpatialService.getCenter(sector).geometry.coordinates,
          text: sector.name,
        });
      });


      setSectors(sectorsData);
      setLayers(layerService.getLayers());
    });
  }, [geoSpatialService, layerService]);

  return (
    <Container>
      <MapSection>
        <MapContainer>
          <DeckGLMapContainer layers={layers} />
        </MapContainer>
      </MapSection>
    </Container>
  );
};

export default withGoogleMapContext(LevisCollectLateralLayout);
