import {Feature, Geometry, Point} from "geojson";

import * as turf from "@turf/turf";

class GeoSpatialService {

  service: typeof turf

  constructor() {
    this.service = turf;
  }

  public getCenter<G extends Geometry = Geometry>(feature: Feature<G>): Feature<Point> {
    return this.service.center(feature);
  }
}

export default GeoSpatialService;
