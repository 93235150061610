import { ROUTES } from "@components/routing/RouterV2";

export const ROUTES_PARAMETERS = {
  locationId: ":locationId",
  routeId: ":routeId",
};

export const LOCATIONS_SUB_ROUTES = {
  location: `/${ROUTES_PARAMETERS.locationId}`,
};

export const LOCATIONS_ROUTES = {
  location: `${ROUTES.locations}/${ROUTES_PARAMETERS.locationId}`,
}


export const ROUTE_ROUTES = {
  route: `${ROUTES.routes}/${ROUTES_PARAMETERS.routeId}`,
}

export const ROUTE_SUB_ROUTES = {
  route: `/${ROUTES_PARAMETERS.routeId}`,
}
