import React, { ReactElement } from "react";
import { useGoogleMapContext } from "@context/googleMap/GoogleMapContext";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import { DeckGL } from "@deck.gl/react";
import { LayersList, MapViewState } from "@deck.gl/core";

const INITIAL_VIEW_STATE: MapViewState = {
  latitude: 46.807763,
  longitude: -71.38549,
  zoom: 10,
};

export type DeckGLMapContainerProps = {
  children?: ReactElement | ReactElement[];
  center?: google.maps.LatLngLiteral;
  layers?: LayersList;
};

const DeckGLMapContainer = ({ children, center, layers = [] }: DeckGLMapContainerProps): ReactElement | ReactElement[] => {
  const { mapProps, setZoom, resetCenter } = useGoogleMapContext();
  return (
    <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}>
      <DeckGL
        layers={layers}
        pickingRadius={5}
        initialViewState={INITIAL_VIEW_STATE}
        controller
      >
        <Map
          center={mapProps.center}
          clickableIcons={false}
          defaultCenter={center || mapProps.defaultCenter}
          fullscreenControl={false}
          mapId={import.meta.env.VITE_LINEA_MAP_ID}
          mapTypeId="roadmap"
          onDragstart={() => resetCenter()}
          onZoomChanged={(event) => setZoom(event.detail.zoom)}
          zoom={mapProps.zoom}
        >
          {children}
        </Map>
      </DeckGL>
    </APIProvider>
  );
};

export default DeckGLMapContainer;
