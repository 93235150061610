import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import { isArrayTruthy } from "@utils/common";
import { padStart } from "lodash";

export default function ReportTableProblemCase({ summaries }) {
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align="center">No. dossier</TableCell>
          <TableCell align="center">Arrondissement</TableCell>
          <TableCell align="center">Date de collecte</TableCell>
          <TableCell align="center">Heure</TableCell>
          <TableCell align="center">No. Civique</TableCell>
          <TableCell align="center">Rue</TableCell>
          <TableCell align="center">Commerce</TableCell>
          <TableCell align="center">Type</TableCell>
          <TableCell align="center">Matière</TableCell>
          <TableCell align="center">No.Contenant</TableCell>
          <TableCell align="center">Remarques</TableCell>
          <TableCell align="center">Commentaires supplémentaires</TableCell>
          <TableCell align="center">Avis remis</TableCell>
          <TableCell align="center">Photo</TableCell>
          <TableCell align="center">Collecte</TableCell>
          <TableCell align="center">Numéro de camion</TableCell>
          <TableCell
            align="center"
            style={{ paddingRight: 25 }}
          >
            Commentaire (ville)
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {summaries.map((row, index) => (
          <TableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell align="center">{row.name}</TableCell>
            <TableCell align="center">{row.district}</TableCell>
            <TableCell align="center">{row.date}</TableCell>
            <TableCell align="center">{row.hour}</TableCell>
            <TableCell align="center">{row.civic_number}</TableCell>
            <TableCell align="center">{row.street}</TableCell>
            <TableCell align="center">{row.shop}</TableCell>
            <TableCell align="center">{row.type}</TableCell>
            <TableCell align="center">{row.matter}</TableCell>
            <TableCell align="center">{row.container_number}</TableCell>
            <TableCell align="center">{row.remark}</TableCell>
            <TableCell align="center">{row.added_comment}</TableCell>
            <TableCell align="center">{row.notice_given}</TableCell>
            <TableCell align="center">
              {" "}
              {isArrayTruthy(row.photos) ? (
                row.photos.map((photo) => {
                  return (
                    <a
                      href={photo}
                      target="_blank"
                      rel="noopener"
                      aria-label="Github"
                    >
                      {photo ? "Oui" : "N/D"}
                    </a>
                  );
                })
              ) : (
                <a
                  href={row.photo}
                  target="_blank"
                  rel="noopener"
                  aria-label="Github"
                >
                  {row.photo ? "Oui" : "N/D"}
                </a>
              )}
            </TableCell>
            <TableCell align="center">{row.collect}</TableCell>
            <TableCell align="center">{row.truck_number}</TableCell>
            <TableCell
              align="center"
              style={{ paddingRight: 25 }}
            >
              {row.city_comment}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
