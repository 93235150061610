import React, { lazy } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { LoadingSpinner } from "@components/routes/utils";

const LocationRoutes = lazy(() => import("@components/routing/routes/LocationRoutes"));
const RouteRoutes = lazy(() => import("@components/routing/routes/RouteRoutes"));

export interface LineaRoutes {
  locations: string;
  routes: string;

  [key: string]: string;
}

export const ROUTES: LineaRoutes = {
  locations: "/emplacements",
  routes: "/routes",
};

function RouterV2(): JSX.Element {
  return (
    <React.Suspense fallback={<LoadingSpinner />}>
      <Outlet />
      <Routes>
        <Route
          path={`${ROUTES.locations}/*`}
          element={<LocationRoutes />}
        />
        <Route
          path={`${ROUTES.routes}/*`}
          element={<RouteRoutes />}
        />
      </Routes>
    </React.Suspense>
  );
}

export default RouterV2;
