import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  max-height: calc(100vh - 64px);
`;

export const MapSection = styled.div`
  position: relative;
  flex: 2 1;
  height: 100vh;
  max-height: calc(100vh - 64px);
`;

export const MapContainer = styled.div`
  flex: 1;
  height: 100vh;
  max-height: calc(100vh - 64px);
`;
